import React from 'react';

const Icon = ({ icon }) => {
  return (
    <svg data-token-icon width='22' height='22' viewBox='0 0 1024 1024'>
      <path d={icon}></path>
    </svg>
  );
};

export default Icon;
