import React from 'react';
import { DIRECTION } from '../../constants';
import { trimToWordCount } from '../../util/string';
import Icon from '../icon';
import { ARROW_LEFT, ARROW_RIGHT } from '../../icons';
import { nextPrevStyles } from './nextPrev.module.scss';

// const maxLabelLength = 35;

const NextPrev = ({ children }) => {
  return (
    <nav data-token-nextprev>
      <ul>{children}</ul>
    </nav>
  );
};

const Next = ({ to, maxLabelWords, children }) =>
  getItem({ to, maxLabelWords, children, direction: DIRECTION.NEXT });
const Prev = ({ to, maxLabelWords, children }) =>
  getItem({ to, maxLabelWords, children, direction: DIRECTION.PREV });

const getItem = ({ to, maxLabelWords, children, direction = DIRECTION.NEXT }) => {
  const label = maxLabelWords ? trimToWordCount(children, maxLabelWords) : children;

  // const icon = DIRECTION.NEXT ? ARROW_RIGHT : ARROW_LEFT;
  const leftArrow = direction === DIRECTION.PREV && <Icon icon={ARROW_LEFT} />;
  const rightArrow = direction === DIRECTION.NEXT && <Icon icon={ARROW_RIGHT} />;

  return (
    <li className={`${direction} ${nextPrevStyles}`}>
      <a href={to}>
        {leftArrow}
        {label}
        {rightArrow}
      </a>
    </li>
  );
};

NextPrev.Next = Next;
NextPrev.Prev = Prev;

export default NextPrev;
