const trimToCharLength = (str, len, ellipses = '...') => {
  if (str.length <= len) return str;
  return str.substring(0, len - ellipses.length) + ellipses;
};

const trimToWordCount = (str, maxWords, ellipses = '...') => {
  const words = str.split(' ');
  if (words.length <= maxWords) return str;
  words.length = maxWords;
  return words.join(' ') + ellipses;
};

export { trimToCharLength, trimToWordCount };
