import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Section } from '../components/containers';
import NextPrev from '../components/nextPrev/';

export default function Page({ data, pageContext }) {
  const { frontmatter, html } = data.markdownRemark;
  const { title, friendlyDate } = frontmatter;

  const { newer, older } = pageContext;
  const maxNextPrevLabelWords = 6;

  const olderLink = older && (
    <NextPrev.Next to={older.slug} maxLabelWords={maxNextPrevLabelWords}>
      {older.frontmatter.title}
    </NextPrev.Next>
  );
  const newerLink = newer && (
    <NextPrev.Prev to={newer.slug} maxLabelWords={maxNextPrevLabelWords}>
      {newer.frontmatter.title}
    </NextPrev.Prev>
  );

  return (
    <Layout template='blogPost'>
      <Seo title={frontmatter.title} />

      <Section>
        <h1>{title}</h1>
        <p>{friendlyDate}</p>
      </Section>

      <Section halign='left' dangerouslySetInnerHTML={{ __html: html }}></Section>

      <Section isCompact>
        <NextPrev>
          {newerLink}
          {olderLink}
        </NextPrev>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        tags
        date
        year: date(formatString: "YYYY")
        relativeDate: date(fromNow: true)
        friendlyDate: date(formatString: "MMMM DD, YYYY")
        category
      }
      html
    }
  }
`;
